<template>
  <div>
    <CRow>
      <CCol md="12" lg="12">
        <CCard>
          <CCardHeader>
            <h3 class="card-title "><strong>{{requestFacility.name}}</strong>{{ $t("facilityRelationUserSearch.pageTitle") }}</h3>
          </CCardHeader>
          <CCardBody>
            <!-- <p>宿を検索して、次の操作を実施します。</p>
            <div class="bd-example mb-5">
              <li class="h6">宿の情報を編集すること</li>
              <li class="h6">宿のオペレーターの指定すること</li>
              <li class="h6">宿のステータス・ダッシュボードを確認すること</li>
            </div> -->
            <h4 class="sub-title">{{ $t("facilityRelationUserSearch.userSearch") }}</h4>
            <p>{{ $t("facilityRelationUserSearch.userSearchGuide") }}</p>
            <CForm>
              <CRow class="mb-2">
                <CCol lg="8">
                  <CInput
                    :label="$t('facilityRelationUserSearch.userEmail')"
                    class="search-input"
                    :placeholder="$t('facilityRelationUserSearch.userEmailPlaceholder')"
                    horizontal
                    type="text"
                    v-model="query.email"
                    @keypress.enter="onEnterKeyPress($event,'email')"
                  />
                </CCol>
                <CCol lg="2">
                <CButton
                    @click="onSearchClicked('email')"
                    class="info-button"
                    color="info"
                >
                  <i class="icon cil-search"></i> {{$t("facilityRelationUserSearch.searchButton")}}
                </CButton>
                  <!-- 検索 -->
                </CCol>
               </CRow>
               <CRow class="mb-2">
                <CCol lg="8">
                  <CInput
                    :label="$t('facilityRelationUserSearch.userName')"
                    class="search-input"
                    :placeholder="$t('facilityRelationUserSearch.userNamePlaceholder')"
                    horizontal
                    type="text"
                    v-model="query.userName"
                    @keypress.enter="onEnterKeyPress($event,'userName')"
                  />
                </CCol>
                <CCol lg="2">
                <CButton
                    @click="onSearchClicked('userName')"
                    class="info-button"
                    color="info"
                >
                  <i class="icon cil-search"></i> {{$t("facilityRelationUserSearch.searchButton")}}
                </CButton>
                  <!-- 検索 -->
                </CCol>
               </CRow>
              <transition name="fade">
                <CRow v-if="userNotFound">
                  <CCol lg="6">
                    <CAlert color="danger">{{$t("facilityRelationUserSearch.userNotFoundAlert")}}</CAlert>
                  </CCol>
                </CRow>
              </transition>
            </CForm>
            <div class="user-info-table">
                <dl class="row">
                  <dt class="col-sm-12">
                    <h4 class="sub-title">{{$t("facilityRelationUserSearch.userSearchList")}}</h4>
                    <p><span>{{$t("facilityRelationUserSearch.totalNumber")}}</span>: {{userCount}} | <span>{{$t("facilityRelationUserSearch.resultNumber")}}</span>: {{searchUserCount}}</p>
                  </dt>
                </dl>
                <CRow class="mb-2">
                <CCol sm="3" class="paginationInput">
                  <CSelect
                    :label="$t('facilityRelationUserSearch.pagenationLabel')"
                    placeholder=""
                    horizontal
                    type="number"
                    :options="searchPagenationCounts"
                    :value.sync="searchPagenationSelected"
                  />
                </CCol>
              </CRow>
                <CDataTable
                  class="mb-0 table-outline"
                  hover
                  size="sm"
                  :items="users"
                  :fields="userTableFields"
                  head-color="light"
                  table-filter
                  column-filter
                  sorter
                  itemps-per-page-select
                  :items-per-page="searchPagenationSelected"
                  pagination
                  v-if="!userLoading"
                  :tableFilter='{ placeholder: $t("tableFilter.placeholder"), label: $t("tableFilter.label") }'
                >
                  <!-- sorter
                :sorterValue.sync="tableSorter" -->

                  <td slot="userName" slot-scope="{ item }">
                    <strong>{{ item.userName }}</strong>
                  </td>
                  <td slot="fullName" slot-scope="{ item }">
                    <strong>{{ item.fullName }}</strong>
                  </td>
                  <td slot="mobile" slot-scope="{ item }">
                    <strong>{{ item.mobile }}</strong>
                  </td>
                  <td slot="email" slot-scope="{ item }">
                    <strong>{{ item.email }}</strong>
                  </td>
                  <template #facility_user_relation_create="{item}">
                    <td class="py-2 text-center">
                      <CButton
                        color="info"
                        square
                        block
                        size="sm"
                        v-if="!item.isRelation"
                        @click="onFacilityUserRelationSaveClicked(item)"
                        >{{$t("facilityRelationUserSearch.usersList.tableBody.userRelationButton")}}</CButton
                      >
                      <span v-if="item.isRelation">
                      <i
                      class="icon cil-check-circle"
                      ></i>{{$t("facilityRelationUserSearch.usersList.tableBody.userRelated")}}</span>
                    </td>
                  </template>
                </CDataTable>
              </div>
              </CCardBody>
              
              <!-- start_facilityUsersTable -->
              <CCardBody>
              <div class="user-info-table">
                <dl class="row">
                  <dt class="col-sm-12">
                    <h4 class="sub-title">{{requestFacility.name}}：{{$t("facilityRelationUserSearch.facilityUserList.fasilityUserList")}}</h4>
                    <p><span>{{$t("facilityRelationUserSearch.facilityUserList.facilityUserNumber")}}</span>: {{facilityUsers.length}}</p>
                  </dt>
                </dl>
                <transition name="fade">
                <CRow v-if="facilityUsersNotFound">
                  <CCol lg="6">
                    <CAlert color="danger">{{$t("facilityRelationUserSearch.facilityUserList.facilityUserNotfound")}}</CAlert>
                  </CCol>
                </CRow>
              </transition>
              <CRow class="mb-2">
                <CCol sm="3" class="paginationInput">
                  <CSelect
                    :label="$t('facilityRelationUserSearch.facilityUserList.pagenationLabel')"
                    placeholder=""
                    horizontal
                    type="number"
                    :options="relationPagenationCounts"
                    :value.sync="relationPagenationSelected"
                  />
                </CCol>
              </CRow>
                <CDataTable
                  class="mb-0 table-outline"
                  hover
                  size="sm"
                  :items="facilityUsers"
                  :fields="facilityUsersTableFields"
                  head-color="light"
                  table-filter
                  column-filter
                  sorter
                  itemps-per-page-select
                  :items-per-page="relationPagenationSelected"
                  pagination
                  :tableFilter='{ placeholder: $t("tableFilter.placeholder"), label: $t("tableFilter.label") }'
                >
                  <!-- sorter
                :sorterValue.sync="tableSorter" -->

                  <td slot="userName" slot-scope="{ item }">
                    <strong>{{ item.userName }}</strong>
                  </td>
                  <td slot="name" slot-scope="{ item }">
                    <strong>{{ item.familyName }}</strong>&nbsp;<strong>{{ item.givenName }}</strong>
                  </td>
                  <td slot="telephone" slot-scope="{ item }">
                    <strong>{{ item.telephone }}</strong>
                  </td>
                  <td slot="email" slot-scope="{ item }">
                    <strong>{{ item.email }}</strong>
                  </td>
                  <template #facility_user_relation_delete="{item}">
                    <td class="py-2 text-center">
                      <CButton
                        color="info"
                        square
                        block
                        size="sm"
                        @click="onFacilityUserRelationDeleteClicked(item)"
                        >{{$t("facilityRelationUserSearch.facilityUserList.tableBody.userRelationDeleteButton")}}</CButton
                      >
                    </td>
                  </template>
                </CDataTable>
              </div>
              <!-- end_fasilityUserTable -->
          </CCardBody>
          <CCardFooter>
            <!-- #region 戻るボタン -->
            <CButton
              color="secondary"
              @click="onBackClicked()"
            ><i class="icon cil-arrow-left mr-2"></i>{{ $t("common.back") }}</CButton>
            <!-- #endregion 戻るボタン -->
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>

    <!-- start_relationSaveConfirmModal -->
    <CModal
      color="info"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="relationSaveConfirmModal"
    >
      <template #header>
        <h5>{{$t("facilityRelationUserSearch.relationSaveConfirmModal.modalTitle")}}</h5>
      </template>
      <CRow class="mb-2">
        <CCol lg="12">
          <p>{{$t("facilityRelationUserSearch.relationSaveConfirmModal.modalContent.modalGuide")}}</p>
        </CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol lg="12">
          <p><strong>{{$t("facilityRelationUserSearch.relationSaveConfirmModal.modalContent.relationFacility")}}</strong></p>
        </CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol lg="4">
          <p>{{$t("facilityRelationUserSearch.relationSaveConfirmModal.modalContent.facilityName")}}:</p>
        </CCol>
        <CCol lg="8">
          <p>{{requestFacility.name}}</p>
        </CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol lg="12">
          <p><strong>{{$t("facilityRelationUserSearch.relationSaveConfirmModal.modalContent.relationUser")}}</strong></p>
        </CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol lg="4">
          <p>{{$t("facilityRelationUserSearch.relationSaveConfirmModal.modalContent.userName")}}:</p>
        </CCol>
        <CCol lg="8">
          <p>{{requestUser.userName}}</p>
        </CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol lg="4">
          <p>{{$t("facilityRelationUserSearch.relationSaveConfirmModal.modalContent.name")}}:</p>
        </CCol>
        <CCol lg="8">
          <p>{{requestUser.familyName}} {{requestUser.givenName}}</p>
        </CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol lg="4">
          <p>{{$t("facilityRelationUserSearch.relationSaveConfirmModal.modalContent.email")}}:</p>
        </CCol>
        <CCol lg="8">
          <p>{{requestUser.email}}</p>
        </CCol>
      </CRow>
      <template #footer>
        <CButton
          @click="relationSaveConfirmModal = false"
          color="secondary"
          class="float-left"
          >
          <i class="icon cil-x"></i>
          {{$t("facilityRelationUserSearch.relationSaveConfirmModal.cancelButton")}}
          </CButton>
        <CButton
          color="success"
          @click="saveFacilityUserRelation()"
          >
          <i class="icon cil-save"></i>
          {{$t("facilityRelationUserSearch.relationSaveConfirmModal.relationSaveButton")}}
        </CButton>
      </template>
    </CModal>
    <!-- end_relationSaveConfirmModal -->

     <!-- start_relationSaveCompleteModal -->
    <CModal
      :show.sync="relationSaveCompleteModal"
      color="success"
      closeOnBackdrop
      :centered="true"
    >
      <template #header>
      <h5>{{$t("facilityRelationUserSearch.relationSaveCompleteModal.modalTitle")}}</h5>
      </template>

      <p>{{$t("facilityRelationUserSearch.relationSaveCompleteModal.modalContent.completeResult")}}</p>
      
      <template #footer>
        <CButton @click="relationSaveCompleteModal = false" color="secondary"
          >{{$t("facilityRelationUserSearch.relationSaveCompleteModal.closeButton")}}</CButton
        >
      </template>
    </CModal>
    <!-- end_relationSaveCompleteModal -->

    <!-- start_relationDeleteConfirmModal -->
    <CModal
      color="warning"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="relationDeleteConfirmModal"
    >
      <template #header>
        <h5>{{$t("facilityRelationUserSearch.relationDeleteConfirmModal.modalTitle")}}</h5>
      </template>

      <CRow class="mb-2">
        <CCol lg="12">
          <p>{{$t("facilityRelationUserSearch.relationSaveConfirmModal.modalContent.modalGuide")}}</p>
        </CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol lg="4">
          <p>{{$t("facilityRelationUserSearch.relationSaveConfirmModal.modalContent.facilityName")}}:</p>
        </CCol>
        <CCol lg="8">
          <p>{{requestFacility.name}}</p>
        </CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol lg="4">
          <p>{{$t("facilityRelationUserSearch.relationDeleteConfirmModal.modalContent.name")}}:</p>
        </CCol>
        <CCol lg="8">
          <p>{{requestUser.familyName}} {{requestUser.givenName}}</p>
        </CCol>
      </CRow>
      <CRow class="mb-2">
        <CCol lg="4">
          <p>{{$t("facilityRelationUserSearch.relationDeleteConfirmModal.modalContent.email")}}:</p>
        </CCol>
        <CCol lg="8">
          <p>{{requestUser.email}}</p>
        </CCol>
      </CRow>
      <template #footer>
        <CButton
          @click="relationDeleteConfirmModal = false"
          color="secondary"
          class="float-left"
          >
          <i class="icon cil-x"></i>
          {{$t("facilityRelationUserSearch.relationDeleteConfirmModal.cancelButton")}}
        </CButton>
        <CButton
          color="danger"
          @click="deleteFacilityUserRelation()"
          >
        <i class="icon cil-save"></i>
        {{$t("facilityRelationUserSearch.relationDeleteConfirmModal.deleteButton")}}
        </CButton>
      </template>
    </CModal>
    <!-- end_relationDeleteConfirmModal -->

    <!-- start_relationDeleteCompleteModal -->
    <CModal
      :show.sync="relationDeleteCompleteModal"
      color="success"
      closeOnBackdrop
      :centered="true"
    >
      <template #header>
      <h5>{{$t("facilityRelationUserSearch.relationDeleteCompleteModal.modalTitle")}}</h5>
      </template>

      <p>{{$t("facilityRelationUserSearch.relationDeleteCompleteModal.modalContent.modalGuide")}}</p>
      
      <template #footer>
        <CButton @click="relationDeleteCompleteModal = false" color="secondary"
          >{{$t("facilityRelationUserSearch.relationDeleteCompleteModal.closeButton")}}</CButton
        >
      </template>
    </CModal>
    <!-- end_relationDeleteCompleteModal -->

    <!-- start_relationSaveErrorModal -->
    <CModal
      :show.sync="relationSaveErrorModal"
      color="danger"
      closeOnBackdrop
      :centered="true"
    >
      <template #header>
        <h5>{{$t("facilityRelationUserSearch.relationSaveErrorModal.modalTitle")}}</h5>
      </template>

      <p>{{$t("facilityRelationUserSearch.relationSaveErrorModal.modalContent.errorResult")}}</p>
      <p>{{$t("facilityRelationUserSearch.relationSaveErrorModal.modalContent.errorSolution")}}</p>
      
      <template #footer>
        <CButton @click="relationSaveErrorModal = false" color="secondary"
          >{{$t("facilityRelationUserSearch.relationSaveErrorModal.closeButton")}}</CButton
        >
      </template>
    </CModal>
    <!-- end_relationSaveErrorModal -->

     <!-- start_relationDeleteErrorModal -->
    <CModal
      :show.sync="relationDeleteErrorModal"
      color="danger"
      closeOnBackdrop
      :centered="true"
    >
      <template #header>
        <h5>{{$t("facilityRelationUserSearch.relationDeleteErrorModal.modalTitle")}}</h5>
      </template>

      <p>{{$t("facilityRelationUserSearch.relationDeleteErrorModal.modalContent.errorResult")}}</p>
      <p>{{$t("facilityRelationUserSearch.relationDeleteErrorModal.modalContent.errorSolution")}}</p>
      
      <template #footer>
        <CButton @click="relationDeleteErrorModal = false" color="secondary"
          >{{$t("facilityRelationUserSearch.relationDeleteErrorModal.closeButton")}}</CButton
        >
      </template>
    </CModal>
    <!-- end_relationDeleteErrorModal -->
  </div>
</template>

<script>
import i18n from "../../../i18n"
import axios from "axios"
import config from "../../../config"
// import Loading from '../../components/Loading.vue';

//会員システムのurl
const LIBERTY_MEMBER_SERVER_URL = config.LIBERTY_MEMBER_SERVER_URL;
// const LIBERTY_MEMBER_SERVER_URL = "https://points.liberty-resort.net/";

export default {
  // components: { Loading },
  name: "UserRelation",
  data() {
    return {
      userNotFound:false,
      userLoading: false,
      userCount: 0,
      searchUserCount: 0,
      facilityUsersNotFound:false,
      relationSaveCompleteModal:false,
      relationSaveErrorModal:false,
      relationSaveConfirmModal:false,
      relationDeleteConfirmModal:false,
      relationDeleteCompleteModal:false,
      relationDeleteErrorModal:false,
      searchPagenationCounts:[{value:5},{value:10},{value:20},{value:50}],
      searchPagenationSelected:5,
      relationPagenationCounts:[{value:5},{value:10},{value:20},{value:50}],
      relationPagenationSelected:5,

      query:{
        email:"",
        userName:""
      },

      // ユーザー検索結果
      users:[],


      // 宿担当者一覧
      facilityUsers:[],

      // リクエストするユーザーデータ
      requestUser: {
        id:"",
        userName:"",
        firstName: "",
        lastName: "",
        familyName:"",
        givenName:"",
        email: "",
        telephone: "",
        description:"",
        status:1,
        type:"10"
      },

      // リクエストに使う宿情報
      requestFacility:{},

      facilityUsersTableFields:[
        {
          key: "userName",
          label: `${i18n.t("facilityRelationUserSearch.facilityUserList.tableHeader.userName")}`,
          sorter: true,
          filter: true,
          _classes: "text-center bg-light"
        },
        { 
          key: "name",
          label: `${i18n.t("facilityRelationUserSearch.facilityUserList.tableHeader.name")}`,
          sorter: true,
          filter: true,
          _classes: "text-center bg-light"
        },
        { 
          key: "telephone",
          label: `${i18n.t("facilityRelationUserSearch.facilityUserList.tableHeader.telephone")}`,
          sorter: true,
          filter: true,
          _classes: "text-center bg-light"
        },
        { 
          key: "email",
          label: `${i18n.t("facilityRelationUserSearch.facilityUserList.tableHeader.email")}`,
          sorter: true,
          filter: true,
          _classes: "text-center bg-light"
        },
        {
          key: "facility_user_relation_delete",
          label:  `${i18n.t("facilityRelationUserSearch.facilityUserList.tableHeader.userRelationDelete")}`,
          sorter: false,
          filter: false,
          _classes: "text-center bg-light",
        }
      ],
     
      userTableFields: [
        {
          key: "userName",
          label: `${i18n.t("facilityRelationUserSearch.usersList.tableHeader.userName")}`,
          sorter: true,
          filter: true,
          _classes: "text-center bg-light"
        },
        { 
          key: "fullName",
          label: `${i18n.t("facilityRelationUserSearch.usersList.tableHeader.name")}`,
          sorter: true,
          filter: true,
          _classes: "text-center bg-light"
        },
        { 
          key: "mobile",
          label: `${i18n.t("facilityRelationUserSearch.usersList.tableHeader.telephone")}`,
          sorter: true,
          filter: true,
          _classes: "text-center bg-light"
        },
        { 
          key: "email",
          label: `${i18n.t("facilityRelationUserSearch.usersList.tableHeader.email")}`,
          sorter: true,
          filter: true,
          _classes: "text-center bg-light"
        },
        {
          key: "facility_user_relation_create",
          label: `${i18n.t("facilityRelationUserSearch.usersList.tableHeader.userRelation")}`,
          sorter: true,
          filter: true,
          _classes: "text-center bg-light",
        }
      ],
    };
  },
  created() {
    this.getRelationFacility(this.uid)
  },
  computed: {
    /**宿ID取得 */
    uid() {
      return this.$store.state.uniqueId;
    },
  },
  methods: {
    
    /** 戻るボタン押下 */
    onBackClicked() {
      this.backPage();
    },

    backPage() {
      this.$router.go(-1);
    },

    getRelationFacility(uniqueId){
      //Login as registered user
      var urlRequest = `/rest/hotel/${uniqueId}`;
      // var urlRequest = `http://localhost:8080/rest/hotel/${uniqueId}`;
      axios
        .get(urlRequest, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {

          this.requestFacility = resp.data;
          // console.log(this.requestFacility);

        })
        .catch((err) => {
          console.log(err);
        });
    },

    
     onEnterKeyPress: function (event, value) {
      // 日本語入力中のEnterキー操作は無効
      if (event.keyCode !== 13) return;
      switch (value) {
        // FIXME:ユーザー検索時にenterキー押すとページが更新されてしまう為修正が必要（原因不明）
        case "email":
          if (!this.query.email) return;
          break;
        // FIXME:ユーザー名取得apiが完成したら修正すること
        case "userName":
          if (!this.query.userName) return;
          break;
        default:
          return;
      }
      this.onSearchClicked(value);
    },


    // 検索ボタン押下
    onSearchClicked(value){
      var urlRequest;

      this.facilityNotFound = false;

      switch (value) {
        case "email":
          var email = encodeURIComponent(this.query.email);
          urlRequest = `${LIBERTY_MEMBER_SERVER_URL}/rest/users?email=${email}`;
          this.getUsers(urlRequest);
          break;
          
        case "userName":
          urlRequest = `${LIBERTY_MEMBER_SERVER_URL}/rest/users/${this.query.userName}`;
          this.getUsers(urlRequest);
          break;
        default:
          return;
      }
    },



    // 全ユーザー件数取得
    getUserCount(){

      //Login as registered user
      var urlRequest = `${LIBERTY_MEMBER_SERVER_URL}/rest/users`;
      axios
        .get(urlRequest, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {

          // console.log(resp.data);
          this.userCount = resp.data;

        })
        .catch((err) => {
          console.log(err);
        });

    },

    // テーブルボディを作成
    createUserTableBodies(users){

      // 検索内容リセット
      // これを消すと連続で検索ボタン押下時にテーブルにデータが追加されてしまう
      this.users = [];

      users.forEach(user => {
        // 各ユーザーをオブジェクトとして作成
        // saveUser()する際にid,statusが必要の為、ここで用意
        // CoreUIのテーブルがフラットな構造のデータ出ないとソート機能が使えない為、オブジェクトを作っている
        var userObj = {
          id : user.id,
          userName:user.name,
          lastName:user.lastName,
          firstName:user.firstName,
          familyName:user.familyName,
          givenName:user.givenName,
          fullName:`${user.familyName} ${user.givenName}(${user.lastName} ${user.firstName})`,
          mobile : user.mobile,
          email : user.email,
          status : user.status,
          // これを削除するとテーブル内の宿関連付けボタンの表示更新がおかしくなる
          isRelation : false
        }
        this.users.push(userObj);
      });

      console.log(this.users);
    },

    // リレーションチェック
    refreshIsRelationStatus() {

      // console.log("forEach前")
      // console.log(this.facilities)
      // console.log(this.userFacilities)

      this.users.forEach((a) => {

        // チェック前にRelation状態を初期化
        // これを削除するとテーブル内の宿関連付けボタンの表示更新がおかしくなる
        a.isRelation = false;
        // 担当している宿の中で検索した宿IDが存在しているか
        a.isRelation = this.facilityUsers.some(
          (b) => a.id === b.id
        );
        console.log(a.name)
        console.log(a.isRelation)
      });


    },
    

    // ユーザー検索
    getUsers(urlRequest){

      this.userNotFound = false;

      axios
        .get(urlRequest, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {

          // レスポンスデータからテーブルボディ情報作成
          this.createUserTableBodies(resp.data)
          console.log(resp.data);

          // リレーションチェック
          this.refreshIsRelationStatus();
          // 検索件数を格納
          this.searchUserCount = this.users.length;

          // 検索件数が0件の時
          if(this.searchUserCount == 0){
            this.userNotFound = true;
          }

        })
        .catch((err) => {
          console.log(err);
          this.userNotFound = true;
        });

    },

    // 宿xユーザーのリレーション登録実行
    saveFacilityUserRelation(){
      // alert("type"+this.requestUser.type);

      // ラジオボタンのvalue(string)→numberに変換
      // const requestTypeNum = Number(this.requestUser.type);
      // →※ユーザータイプは宿オペレーターのみの為、固定値を送信する
      const requestTypeNum = 10;

      // postパラメータ
      var requestParm = {
            userId: this.requestUser.id,
            // 宿オペレーターのみが関連づくためタイプは固定値
            type: requestTypeNum // Operator = 10, Staff = 20
          }

      //Login as registered user
      var urlRequest = `/rest/hotel/${this.requestFacility.uniqueId}/user`;
      // var urlRequest = `http://localhost:8080/rest/hotel/${this.requestFacility.uniqueId}/user`;
      axios
        .post(
          urlRequest,
          requestParm,
          {headers: { Authorization: `Bearer ${this.$keycloak.token}` }}
        )
        .then((resp) => {
          console.log(resp.data);
           // 処理成功

           // 宿担当者一覧リセット
          this.facilityUsers=[];
          // 宿担当者一覧アラートリセット
          this.facilityUsersNotFound = false;

          // // 宿担当者再取得
          // this.getFacilityUsers(this.requestFacility.uniqueId);
          // 宿担当者
          const callback = () => this.refreshIsRelationStatus();
          this.getFacilityUsers(this.requestFacility.uniqueId, callback);

          // リレーションついた人は登録済みになるようにステータスを更新
          this.refreshIsRelationStatus();

          this.relationSaveConfirmModal = false;

          this.relationSaveCompleteModal = true;
        })
        .catch((err) => {
          console.log(err);
           // 処理失敗
          this.relationSaveConfirmModal = false;
          this.relationSaveErrorModal = true;
        });

    },

    


    // 宿xユーザーのリレーション登録ボタン押下
    onFacilityUserRelationSaveClicked(user){

      // 宿探しに保存するユーザー情報作成
      this.requestUser.id = user.id;
      this.requestUser.userName = user.userName;
      this.requestUser.firstName = user.firstName;
      this.requestUser.lastName = user.lastName;
      this.requestUser.familyName = user.familyName;
      this.requestUser.givenName = user.givenName;
      this.requestUser.email = user.email;
      this.requestUser.telephone = user.mobile;
      this.requestUser.description = "";
      this.requestUser.status = user.status;

      console.log(this.requestUser)

      //Login as registered user
      // 宿探しに関連付けするユーザーを保存・すでに保存されてれば更新する
      var urlRequest = `/rest/user`;
      // var urlRequest = `http://localhost:8080/rest/user`;
      axios
        .post(
            urlRequest, 
            this.requestUser,
            {headers: { Authorization: `Bearer ${this.$keycloak.token}` },})
        .then((resp) => {

          // レスポンス格納
          console.log(resp.data);

          // ユーザーのタイプ選択モーダル表示
          this.showRelationSaveConfirmModal();

          // // リレーション登録
          // this.saveFacilityUserRelation();

        })
        .catch((err) => {

          console.log(err);
          this.relationSaveErrorModal = true;
        });

    },


    // // 宿xユーザーのリレーション削除実行
    deleteFacilityUserRelation(){

      //Login as registered user
      var urlRequest = `/rest/hotel/${this.requestFacility.uniqueId}/user/${this.requestUser.id}`;
      // var urlRequest = `http://localhost:8080/rest/hotel/${this.requestFacility.uniqueId}/user/${this.requestUser.id}`;
      axios
        .delete(
          urlRequest,
          {headers: { Authorization: `Bearer ${this.$keycloak.token}` }}
        )
        .then((resp) => {
          console.log(resp.data);
           // 処理成功

          // 宿担当者一覧リセット
          this.facilityUsers=[];
          // 宿担当者一覧アラートリセット
          this.facilityUsersNotFound = false;

          // 宿担当者再取得
          // this.getFacilityUsers(this.requestFacility.uniqueId);
           // 宿担当者
          const callback = () => this.refreshIsRelationStatus();
          this.getFacilityUsers(this.requestFacility.uniqueId, callback);

          this.relationDeleteConfirmModal = false;

          this.relationDeleteCompleteModal = true;
        })
        .catch((err) => {
          console.log(err);
           // 処理失敗
          this.relationDeleteConfirmModal = false;
          this.relationDeleteErrorModal = true;
          
        });

    },

    // 宿の担当者関連付け削除ボタン押下
    onFacilityUserRelationDeleteClicked(user){

      //宿の担当者関連付け削除するユーザー情報作成
      this.requestUser.id = user.id;
      this.requestUser.userName = user.userName;
      this.requestUser.firstName = user.firstName;
      this.requestUser.lastName = user.lastName;
      this.requestUser.familyName = user.familyName;
      this.requestUser.givenName = user.givenName;
      this.requestUser.email = user.email;
      this.requestUser.telephone = user.telephone;
      this.requestUser.description = "";
      this.requestUser.status = user.status;

      // ユーザーのタイプ選択モーダル表示
      this.showRelationDeleteConfirmModal();

    },


    showRelationSaveConfirmModal(){
      // ユーザータイプ初期化
      this.requestUser.type = "10";
      this.relationSaveConfirmModal = true;
    },

    showRelationDeleteConfirmModal(){
      this.relationDeleteConfirmModal = true;
    },


    // 宿担当者一覧取得
    getFacilityUsers(uniqueId, callback){
      //Login as registered user
      var urlRequest = `/rest/hotel/${uniqueId}/user`;
      // var urlRequest = `http://localhost:8080/rest/hotel/${uniqueId}/user`;
      axios
        .get(urlRequest,{
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {
          //レスポンスを格納
          
          this.facilityUsers = resp.data.users;
          // console.log(resp.data.users)

          if(this.facilityUsers.length === 0){
            this.facilityUsersNotFound = true;
          }

          // 引数にメソッドが入ってるとき(登録・削除)にリレーション再チェック
          if(callback !==undefined){
            callback();
            console.log("callback");
          }

          // console.log(this.facilityUsers);
        })
        .catch((err) => {
          this.facilityUsersNotFound = true;
          console.log(err);
          if (err.response.status == 404) {
            if(callback !==undefined){
              callback();
              console.log("callback");
            }
           }
        });
    }


  },
  beforeMount() {
    // 全ユーザー件数取得
    this.getUserCount();

    // 宿担当者取得
    this.getFacilityUsers(this.uid);
  },
};
</script>
